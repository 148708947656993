import React, { Component } from 'react';
import Headroom from 'react-headroom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, Row, css, styled, withTheme, up } from '@smooth-ui/core-sc';
import HeaderLogo from 'theme/logo-header-@2x.png';

const CustomHeadroom = styled(Headroom)`
  .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
  }
  .headroom--scrolled {
    transition: transform 200ms ease-in-out;
  }
  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
  }
`;

const HeaderWrapper = styled.div`
  background-color: #dfe9e6;
  height: 110px;
  width: 100%;
  ${up(
    'lg',
    css`
      height: 120px;
      .sui-grid,
      .sui-row {
        height: 120px;
      }
    `
  )};
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  max-width: 100%;
  width: 220px;
  margin-left: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  img {
    height: auto;
    width: 218px;
  }
`;

const HeaderRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-transform: uppercase;
  font-size: 16px;

  ${up(
    'lg',
    css`
      flex-direction: column;
    `
  )}
`;

class Header extends Component {
  render() {
    return (
      <header className="site-header">
        {/* Leave the above <header /> here, necessary to contain Headroom */}
        {/* Otherwise Headroom will wrap all sibling elements */}
        <CustomHeadroom disableInlineStyles downTolerance={10}>
          <HeaderWrapper className="header-wrapper">
            <Grid>
              <Row alignItems="flex-end" justifyContent="space-between">
                <LogoContainer>
                  <Link to="/">
                    <img src={HeaderLogo} alt="Incremental" />
                  </Link>
                </LogoContainer>
                <HeaderRightContainer>
                  {this.props.children}
                </HeaderRightContainer>
              </Row>
            </Grid>
          </HeaderWrapper>
        </CustomHeadroom>
      </header>
    );
  }
}

Header.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object,
};

Header.defaultProps = {
  children: undefined,
  theme: undefined,
};

export default withTheme(Header);
